<template>
  <div>
    <el-card>
          <template #header>
            <el-button @click="clearFilter" style="float: left">reset all filters</el-button>
            <span style="font-size: small">{{$t('msg.route.chargeCodeEdit')}}</span>
            <el-button type="primary" size="small" style="float: right" @click="addRecord()">{{ $t('msg.basicInfo.addRecord') }}</el-button>
          </template>
          <el-table ref="tableRef" :data="chargeCodeList">
            <el-table-column prop="legcode" :label="$t('msg.chargeCode.legcode')" align="center" sortable
                             column-key="legcode" :filters="legFilterList" :filter-method="filterHandler">
              <template #default="scope">
                <el-select v-model="scope.row.legcode" size="small" v-if="scope.$index+editFlag===-1">
                  <el-option v-for="item in legCodeList" :key="item" :label="item" :value="item"/>
                </el-select>
                <span v-else>{{scope.row.legcode}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="chargetype" :label="$t('msg.chargeCode.chargeType')" align="center" width="150px" sortable
                             column-key="chargetype" :filters="typeFilterList" :filter-method="filterHandler">
              <template #default="scope">
                <el-select v-model="scope.row.chargetype" size="small" v-if="scope.$index+editFlag===-1">
                  <el-option v-for="item in chargeTypeList" :key="item" :label="item" :value="item" />
                </el-select>
                <span v-else>{{scope.row.chargetype}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="chargeDescription" :label="$t('msg.chargeCode.chargeDescription')" align="center" width="300px" sortable>
              <template #default="scope">
                <el-input v-model="scope.row.chargeDescription" size="small" v-if="scope.$index+editFlag===-1"></el-input>
                <span v-else>{{scope.row.chargeDescription}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="chargeuom" :label="$t('msg.chargeCode.chargeUOM')" align="center" width="150px" sortable
                             column-key="chargeuom" :filters="uomFilterList" :filter-method="filterHandler">
              <template #default="scope">
                <el-select v-model="scope.row.chargeuom" size="small" v-if="scope.$index+editFlag===-1">
                  <el-option v-for="item in chargeUOMList" :key="item" :label="item" :value="item" />
                </el-select>
                <span v-else>{{scope.row.chargeuom}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="l1" label="L1" align="center" sortable column-key="l1" :filters="l1FilterList" :filter-method="filterHandler">
              <template #default="scope">
                <el-input-number v-model="scope.row.l1" size="small" controls-position="right" :min="0" :step="1" style="width: 100%" v-if="scope.$index+editFlag===-1"/>
                <span v-else>{{scope.row.l1}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="l2" label="L2" align="center" sortable column-key="l2" :filters="l2FilterList" :filter-method="filterHandler">
              <template #default="scope">
                <el-input-number v-model="scope.row.l2" size="small" controls-position="right" :min="0" :step="1" style="width: 100%" v-if="scope.$index+editFlag===-1"/>
                <span v-else>{{scope.row.l2}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="l3" label="L3" align="center" sortable column-key="l3" :filters="l3FilterList" :filter-method="filterHandler">
              <template #default="scope">
                <el-input-number v-model="scope.row.l3" size="small" controls-position="right" :min="0" :step="1" style="width: 100%" v-if="scope.$index+editFlag===-1"/>
                <span v-else>{{scope.row.l3}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="l4" label="L4" align="center" sortable column-key="l4" :filters="l4FilterList" :filter-method="filterHandler">
              <template #default="scope">
                <el-input-number v-model="scope.row.l4" size="small" controls-position="right" :min="0" :step="1" style="width: 100%" v-if="scope.$index+editFlag===-1"/>
                <span v-else>{{scope.row.l4}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="responsible" :label="$t('msg.chargeCode.responsible')" align="center" sortable>
              <template #default="scope">
                <el-input v-model="scope.row.responsible" size="small" v-if="scope.$index+editFlag===-1"></el-input>
                <span v-else>{{scope.row.responsible}}</span>
              </template>
            </el-table-column>
            <el-table-column fixed="right" :label="$t('msg.rate.Operations')" width="200px" align="center">
              <template #default="scope">
                <el-button type="success" size="small" @click="confirmEdit(scope.row)" plain v-if="scope.$index+editFlag===-1">Confirm</el-button>
                <el-button type="danger" size="small" @click="cancelEdit()" plain v-if="scope.$index+editFlag===-1">Cancel</el-button>
                <el-button type="primary" size="small" @click="startEdit(scope.$index)" v-if="scope.$index+editFlag!==-1">Edit</el-button>
                <el-button type="danger" size="small" @click="delRecord(scope.row)" v-if="scope.$index+editFlag!==-1">Del</el-button>
              </template>
            </el-table-column>
          </el-table>
    </el-card>
    <el-dialog title="Add Record" v-model="updateFormShow" v-on:close="resetForm()" width="30%" center draggable>
      <el-form :model="updateForm" label-position="top" label-width="200px">
        <el-row>
          <el-form-item :label="$t('msg.chargeCode.legcode')" prop="legcode">
            <el-select v-model="updateForm.legcode" size="small">
              <el-option v-for="item in legCodeList" :key="item" :label="item" :value="item"/>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('msg.chargeCode.chargeType')" prop="chargetype">
           <el-select v-model="updateForm.chargetype" size="small" style="margin-left: 20px">
            <el-option v-for="item in chargeTypeList" :key="item" :label="item" :value="item"/>
           </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item :label="$t('msg.chargeCode.chargeDescription')" prop="chargeDescription">
            <el-input v-model="updateForm.chargeDescription" size="small"></el-input>
          </el-form-item>
          <el-form-item :label="$t('msg.chargeCode.chargeUOM')" prop="chargeuom">
            <el-select v-model="updateForm.chargeuom" size="small" style="margin-left: 20px">
              <el-option v-for="item in chargeUOMList" :key="item" :label="item" :value="item"/>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item :label="$t('msg.chargeCode.l1')" prop="l1">
            <el-input-number v-model="updateForm.l1" size="small" controls-position="right" :min="0" :step="1"/>
          </el-form-item>
          <el-form-item :label="$t('msg.chargeCode.l2')" prop="l2">
            <el-input-number v-model="updateForm.l2" size="small" controls-position="right" :min="0" :step="1" style="margin-left: 20px"/>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item :label="$t('msg.chargeCode.l3')" prop="l3">
            <el-input-number v-model="updateForm.l3" size="small" controls-position="right" :min="0" :step="1"/>
          </el-form-item>
          <el-form-item :label="$t('msg.chargeCode.l4')" prop="l4">
            <el-input-number v-model="updateForm.l4" size="small" controls-position="right" :min="0" :step="1" style="margin-left: 20px"/>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item :label="$t('msg.chargeCode.responsible')" prop="responsible">
            <el-input v-model="updateForm.responsible" size="small"></el-input>
          </el-form-item>
        </el-row>
     </el-form>
      <el-button type="primary" v-on:click="recordSubmit()" style="float: right">Submit</el-button>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import axios from 'axios'
import { getFilterList } from '@/utils/genTools'

export default defineComponent({
  name: 'chargeCodeEdit',
  setup() {
    const tableRef = ref()
    const editFlag = ref(0)
    const chargeCodeList = ref([{ legcode: '', chargetype: '', chargeDescription: '', chargeuom: '', l1: 0, l2: 0, l3: 0, l4: 0, responsible: '' }])
    const legCodeList = ref([])
    const chargeTypeList = ref([])
    const chargeUOMList = ref([])
    const legFilterList = ref([])
    const typeFilterList = ref([])
    const uomFilterList = ref([])
    const l1FilterList = ref([])
    const l2FilterList = ref([])
    const l3FilterList = ref([])
    const l4FilterList = ref([])
    const updateFormShow = ref(false)
    const updateForm = ref({ legcode: '', chargetype: '', chargeDescription: '', chargeuom: '', l1: 0, l2: 0, l3: 0, l4: 0, responsible: '' })
    const clearFilter = () => {
      tableRef.value.clearFilter()
    }
    const filterHandler = (value, row, column) => {
      return row[column.property] === value
    }
    const initPage = () => {
      const reqURL = process.env.VUE_APP_BASE + '/charCode/getChargeCodeComplex'
      axios.get(reqURL).then((response) => {
        chargeCodeList.value = response.data.chargeCodeList
        legCodeList.value = response.data.legCodeList
        chargeTypeList.value = response.data.chargeTypeList
        chargeUOMList.value = response.data.chargeUOMList
        legFilterList.value = getFilterList(legCodeList.value)
        typeFilterList.value = getFilterList(chargeTypeList.value)
        uomFilterList.value = getFilterList(chargeUOMList.value)
        l1FilterList.value = getFilterList(response.data.l1List)
        l2FilterList.value = getFilterList(response.data.l2List)
        l3FilterList.value = getFilterList(response.data.l3List)
        l4FilterList.value = getFilterList(response.data.l4List)
      }).catch((response) => {
        console.log(response)
      })
    }
    const addRecord = () => {
      if (editFlag.value < 0) {
        alert('Please click comfirm or cancel to close opened input first!')
        return false
      }
      updateFormShow.value = true
    }
    const resetForm = () => {
      editFlag.value = 0
      updateForm.value = { legcode: '', chargetype: '', chargeDescription: '', chargeuom: '', l1: 0, l2: 0, l3: 0, l4: 0, responsible: '' }
    }
    const recordSubmit = () => {
      if (updateForm.value.legcode === '' || updateForm.value.chargetype === '' || updateForm.value.chargeDescription === '') {
        alert('LegCode, ChargeType and ChargeDescription are required!')
        return false
      }
      const param = 'newChargeCode=' + JSON.stringify(updateForm.value)
      const reqURL = process.env.VUE_APP_BASE + '/charCode/insertChargeCode'
      axios.post(reqURL, param).then((response) => {
        chargeCodeList.value = response.data
        updateFormShow.value = false
      }).catch((response) => {
        console.log(response)
      })
    }
    const startEdit = id => {
      if (editFlag.value < 0) {
        alert('Please click comfirm or cancel to close opened input first!')
        return false
      }
      editFlag.value = -(id + 1)
    }
    const cancelEdit = () => {
      editFlag.value = 0
      const reqURL = process.env.VUE_APP_BASE + '/charCode/getChargeCode'
      axios.get(reqURL).then((response) => {
        chargeCodeList.value = response.data
      }).catch((response) => {
        console.log(response)
      })
    }
    const confirmEdit = (row) => {
      if (row.legcode === '' || row.chargetype === '' || row.chargeDescription === '') {
        alert('LegCode, ChargeType and ChargeDescription are required!')
        return false
      }
      editFlag.value = 0
      const param = 'updatedChargeCode=' + JSON.stringify(row)
      const reqURL = process.env.VUE_APP_BASE + '/charCode/updateChargeCode'
      axios.post(reqURL, param).then((response) => {
        chargeCodeList.value = response.data
      }).catch((response) => {
        console.log(response)
      })
    }
    const delRecord = row => {
      const param = 'delChargeCode=' + JSON.stringify(row)
      const reqURL = process.env.VUE_APP_BASE + '/charCode/deleteChargeCode'
      axios.post(reqURL, param).then((response) => {
        chargeCodeList.value = response.data
      }).catch((response) => {
        console.log(response)
      })
    }
    onMounted(() => {
      initPage()
    })
    return { tableRef, editFlag, chargeCodeList, legCodeList, chargeTypeList, chargeUOMList, legFilterList, typeFilterList, uomFilterList, updateFormShow, updateForm, l1FilterList, l2FilterList, l3FilterList, l4FilterList, clearFilter, filterHandler, resetForm, initPage, addRecord, recordSubmit, cancelEdit, confirmEdit, startEdit, delRecord }
  }
})
</script>
